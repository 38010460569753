// Generated by Framer (f92ac27)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {hHKaP6Iz7: {hover: true}};

const cycleOrder = ["hHKaP6Iz7"];

const variantClassNames = {hHKaP6Iz7: "framer-v-1kypsau"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "hHKaP6Iz7", link: vlxuveBQv, image: Z25AWMPUF = {src: new URL("assets/bsq5sfL0S9tr1LLPDkICzmRm7c.png", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "hHKaP6Iz7", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-E8VI2", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Link href={vlxuveBQv}><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 60, intrinsicWidth: 60, pixelHeight: 60, pixelWidth: 60, ...toResponsiveImage_194x2gw(Z25AWMPUF)}} className={`${cx("framer-1kypsau", className)} framer-rsj8p`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hHKaP6Iz7"} ref={ref} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition} variants={{"hHKaP6Iz7-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}} {...addPropertyOverrides({"hHKaP6Iz7-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-E8VI2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-E8VI2 * { box-sizing: border-box; }", ".framer-E8VI2 .framer-rsj8p { display: block; }", ".framer-E8VI2 .framer-1kypsau { height: 32px; overflow: hidden; position: relative; text-decoration: none; width: 32px; }", ".framer-E8VI2 .framer-v-1kypsau .framer-1kypsau { cursor: pointer; }", ".framer-E8VI2.framer-v-1kypsau.hover .framer-1kypsau { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"gWOfYAkNz":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"vlxuveBQv":"link","Z25AWMPUF":"image"}
 */
const FramerKdX4SaXDF: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerKdX4SaXDF;

FramerKdX4SaXDF.displayName = "connect-with-sona-on-linkedin";

FramerKdX4SaXDF.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerKdX4SaXDF, {vlxuveBQv: {title: "Link", type: ControlType.Link}, Z25AWMPUF: {__defaultAssetReference: "data:framer/asset-reference,bsq5sfL0S9tr1LLPDkICzmRm7c.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerKdX4SaXDF, [])